import React from 'react'
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.css";

function VideoBox({ holderstyle = {} }) {
    const handleClick = () => {
        if (typeof window !== `undefined`) {
            const myGallery = GLightbox({
                touchNavigation: true,
                elements: [
                    {
                        'href': 'https://storage.googleapis.com/nosurrender-studio-cdn/cms-assets/ns_main_30sn_comingsoon_16_9_1d034c1a39/ns_main_30sn_comingsoon_16_9_1d034c1a39.mp4',
                        'type': 'video',
                        'source': 'local', //vimeo, youtube or local
                        'width': 900,
                    },
                ],
                autoplayVideos: true,
            });
            myGallery.open();
        }
    }

    return (
        <>
            <a
                className="video-btn mt-5"
                type="button"
                href="#"
                role="button"
                onClick={handleClick}
                style={holderstyle}
            >
                <i className="fas fa-play" />
            </a>
        </>
    )

}

export default VideoBox
